var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('KTPortlet',{staticClass:"noBodyPad",attrs:{"title":"Filter","headClass":_vm.filterConfig.expanded ? '' : 'kt-portlet__head--noborder'}},[_c('template',{slot:"toolbar"},[_c('v-icon',{style:((_vm.filterConfig.expanded ? 'transform: rotate(180deg);' : '') +
          ' cursor: pointer;'),on:{"click":function($event){_vm.filterConfig.expanded = !_vm.filterConfig.expanded}}},[_vm._v("mdi-chevron-up")])],1),(_vm.filterConfig.expanded)?_c('template',{slot:"body"},[_c('v-row',{staticStyle:{"margin":"0 auto","width":"90%"},attrs:{"data-app":""}},[_c('v-col',{attrs:{"cols":6}},[_c('div',{style:(`display: inline-block; width: calc(100% - ${
              _vm.filterConfig.gateway !== null ? '62px' : '0px'
            });`)},[_c('v-autocomplete',{attrs:{"items":_vm.GetGateways,"item-text":"text","item-value":"value","label":"Gateway"},model:{value:(_vm.filterConfig.gateway),callback:function ($$v) {_vm.$set(_vm.filterConfig, "gateway", $$v)},expression:"filterConfig.gateway"}})],1),(_vm.filterConfig.gateway !== null)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"5px"}},[_c('b-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.filterConfig.gateway = null;
                _vm.filterConfig.device = null;}}},[_vm._v("X")])],1):_vm._e()]),_c('v-col',{attrs:{"cols":6}},[_c('div',{style:(`display: inline-block; width: calc(100% - ${
              _vm.filterConfig.device !== null ? '62px' : '0px'
            });`)},[_c('v-autocomplete',{attrs:{"persistent-hint":_vm.filterConfig.gateway !== null,"hint":_vm.filterConfig.gateway !== null
                  ? `Automatically filtered by selected gateway '${
                      _vm.getGateway(_vm.filterConfig.gateway).name
                    }'`
                  : null,"items":_vm.GetDevices,"item-text":"text","item-value":"value","label":"Device"},model:{value:(_vm.filterConfig.device),callback:function ($$v) {_vm.$set(_vm.filterConfig, "device", $$v)},expression:"filterConfig.device"}})],1),(_vm.filterConfig.device !== null)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"5px"}},[_c('b-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.filterConfig.device = null}}},[_vm._v("X")])],1):_vm._e()])],1)],1):_vm._e()],2),_c('KTPortlet',{attrs:{"title":"Log Viewer"}},[_c('template',{slot:"body"},[(_vm.syslogs === null || _vm.gateways == null || _vm.devices === null)?_c('Loader',{attrs:{"inContent":true}},[_vm._v("Loading...")]):(_vm.syslogs.length === 0)?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" No logs to show ")]):_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"sticky-column":""}},[_vm._v("Gateway")]),_c('b-th',{attrs:{"sticky-column":""}},[_vm._v("Device")]),_c('b-th',[_vm._v("Date")]),_c('b-th',[_vm._v("Message")]),_c('b-th',{staticStyle:{"width":"50px"}})],1)],1),_c('b-tbody',_vm._l((_vm.filteredSyslogs),function(syslog){return _c('b-tr',{key:syslog.key,class:syslog.selected === true
                ? 'syslog-select'
                : syslog.new === true
                ? syslog.result.message.toLowerCase().indexOf('critical') >=
                    0 ||
                  syslog.result.message.toLowerCase().indexOf('danger') >=
                    0 ||
                  syslog.result.message.toLowerCase().indexOf('error') >= 0
                  ? 'syslog-row-danger'
                  : syslog.result.message.toLowerCase().indexOf('warning') >=
                    0
                  ? 'syslog-row-warning'
                  : 'syslog-row'
                : ''},[_c('b-th',{attrs:{"sticky-column":""}},[_vm._v(_vm._s(_vm.getGateway(syslog.gatewayId).name))]),_c('b-th',{attrs:{"sticky-column":""}},[_vm._v(_vm._s(_vm.getDevice(syslog.deviceId).name))]),_c('b-td',[_vm._v(_vm._s(_vm.$tools.formatDate(syslog.result.date, "HH:mm:ss DD/MM/YYYY")))]),_c('b-td',[_vm._v(_vm._s(syslog.result.message))]),_c('b-td',{staticStyle:{"width":"50px"}},[(!syslog.selected)?_c('router-link',{attrs:{"to":`/Networks/${_vm.$route.params.serviceId}/Syslog/${syslog.key}`}},[_c('v-icon',[_vm._v("flaticon-eye")])],1):_vm._e()],1)],1)}),1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }