<template>
  <div>
    <KTPortlet
      title="Filter"
      class="noBodyPad"
      :headClass="filterConfig.expanded ? '' : 'kt-portlet__head--noborder'"
    >
      <template slot="toolbar">
        <v-icon
          :style="
            (filterConfig.expanded ? 'transform: rotate(180deg);' : '') +
            ' cursor: pointer;'
          "
          @click="filterConfig.expanded = !filterConfig.expanded"
          >mdi-chevron-up</v-icon
        >
      </template>
      <template slot="body" v-if="filterConfig.expanded">
        <v-row style="margin: 0 auto; width: 90%" data-app>
          <v-col :cols="6">
            <div
              :style="`display: inline-block; width: calc(100% - ${
                filterConfig.gateway !== null ? '62px' : '0px'
              });`"
            >
              <v-autocomplete
                :items="GetGateways"
                item-text="text"
                item-value="value"
                v-model="filterConfig.gateway"
                label="Gateway"
              ></v-autocomplete>
            </div>
            <div
              style="display: inline-block; margin-left: 5px"
              v-if="filterConfig.gateway !== null"
            >
              <b-btn
                @click="
                  filterConfig.gateway = null;
                  filterConfig.device = null;
                "
                small
                text
                >X</b-btn
              >
            </div>
          </v-col>
          <v-col :cols="6">
            <div
              :style="`display: inline-block; width: calc(100% - ${
                filterConfig.device !== null ? '62px' : '0px'
              });`"
            >
              <v-autocomplete
                :persistent-hint="filterConfig.gateway !== null"
                :hint="
                  filterConfig.gateway !== null
                    ? `Automatically filtered by selected gateway '${
                        getGateway(filterConfig.gateway).name
                      }'`
                    : null
                "
                :items="GetDevices"
                item-text="text"
                item-value="value"
                v-model="filterConfig.device"
                label="Device"
              ></v-autocomplete>
            </div>
            <div
              style="display: inline-block; margin-left: 5px"
              v-if="filterConfig.device !== null"
            >
              <b-btn @click="filterConfig.device = null" small text>X</b-btn>
            </div>
          </v-col>
        </v-row>
      </template>
    </KTPortlet>
    <KTPortlet title="Log Viewer">
      <!-- <template v-slot:toolbar>
        <div class="dropdown dropdown-inline">
          <button
            type="button"
            class="btn btn-clean btn-sm btn-icon btn-icon-md"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="flaticon2-settings"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md">
            <div style="padding: 20px;">
              <b-form-group label="View">
                <b-form-radio v-model="showTreeView" size="lg" :value="false">Table view</b-form-radio>
                <b-form-radio v-model="showTreeView" size="lg" :value="true">Hierarchical view</b-form-radio>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>-->
      <template slot="body">
        <Loader
          v-if="syslogs === null || gateways == null || devices === null"
          :inContent="true"
          >Loading...</Loader
        >
        <div
          v-else-if="syslogs.length === 0"
          style="width: 100%; text-align: center"
        >
          No logs to show
        </div>
        <b-table-simple v-else responsive>
          <b-thead>
            <b-tr>
              <b-th sticky-column>Gateway</b-th>
              <b-th sticky-column>Device</b-th>
              <b-th>Date</b-th>
              <b-th>Message</b-th>
              <b-th style="width: 50px"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              :class="
                syslog.selected === true
                  ? 'syslog-select'
                  : syslog.new === true
                  ? syslog.result.message.toLowerCase().indexOf('critical') >=
                      0 ||
                    syslog.result.message.toLowerCase().indexOf('danger') >=
                      0 ||
                    syslog.result.message.toLowerCase().indexOf('error') >= 0
                    ? 'syslog-row-danger'
                    : syslog.result.message.toLowerCase().indexOf('warning') >=
                      0
                    ? 'syslog-row-warning'
                    : 'syslog-row'
                  : ''
              "
              v-for="syslog in filteredSyslogs"
              v-bind:key="syslog.key"
            >
              <b-th sticky-column>{{ getGateway(syslog.gatewayId).name }}</b-th>
              <b-th sticky-column>{{ getDevice(syslog.deviceId).name }}</b-th>
              <b-td>{{
                $tools.formatDate(syslog.result.date, "HH:mm:ss DD/MM/YYYY")
              }}</b-td>
              <b-td>{{ syslog.result.message }}</b-td>
              <b-td style="width: 50px">
                <router-link
                  :to="`/Networks/${$route.params.serviceId}/Syslog/${syslog.key}`"
                  v-if="!syslog.selected"
                >
                  <v-icon>flaticon-eye</v-icon>
                </router-link>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";

export default {
  name: "Syslogs",
  components: {
    KTPortlet,
    Loader,
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // $route.query.logId
        /*for (let syslog of this.$data.syslogs) {
          syslog.selected = this.$route.params.logId === syslog.key;
        }*/
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      syslogs: null,
      types: null,
      gateways: null,
      devices: null,
      filterConfig: {
        expanded: false,
        gateway: null,
        device: null,
      },
    };
  },
  methods: {
    getDevice(id) {
      if (this.$tools.isNullOrUndefined(this.devices)) return id;
      for (let device of this.devices) if (device.id === id) return device;
      return id;
    },
    getGateway(id) {
      if (this.$tools.isNullOrUndefined(this.gateways)) return id;
      for (let gateway of this.gateways) if (gateway.id === id) return gateway;
      return id;
    },
    updateData() {
      let self = this;
      self.$data.syslogs = null;
      TLib.getGateways(self, self.$route.params.serviceId)
        .then((x) => {
          self.$data.gateways = x;
        })
        .catch((er) => {
          self.$log.error(er);
          self.$data.gateways = -1;
        });
      TLib.getDevices(self, self.$route.params.serviceId)
        .then((x) => {
          self.$data.devices = x;
        })
        .catch((er) => {
          self.$log.error(er);
          self.$data.devices = -1;
        });
      self.$nextTick(() => {
        let syslogs = window.sessionStorage.getItem("syslogs");
        if (self.$tools.isNullOrUndefined(syslogs) || syslogs == "")
          return (self.$data.syslogs = []);
        syslogs = JSON.parse(syslogs);
        let mySyslogs = [];
        for (let syslog of syslogs) {
          if (syslog.serviceId !== self.$route.params.serviceId) continue;
          mySyslogs.push(syslog);
        }
        self.$data.syslogs = mySyslogs;
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
    filteredSyslogs() {
      if (this.$tools.isNullOrUndefined(this.syslogs)) return [];

      /*if (this.$data.syslogs.length > 200) {
        this.$data.syslogs.splice(200);
      }*/

      let syslogs = JSON.parse(JSON.stringify(this.$data.syslogs));
      for (let i = 0; i < syslogs.length - 1; i++) {
        for (let j = i + 1; j < syslogs.length; j++) {
          if (syslogs[i].result.date < syslogs[j].result.date) {
            let temp = syslogs[i];
            syslogs[i] = syslogs[j];
            syslogs[j] = temp;
          }
        }
      }
      if (!this.$tools.isNullOrUndefined(this.filterConfig.gateway)) {
        for (let i = 0; i < syslogs.length; i++) {
          if (syslogs[i].gatewayId !== this.filterConfig.gateway) {
            syslogs.splice(i, 1);
            i = -1;
          }
        }
      }
      if (!this.$tools.isNullOrUndefined(this.filterConfig.device)) {
        for (let i = 0; i < syslogs.length; i++) {
          if (syslogs[i].deviceId !== this.filterConfig.device) {
            syslogs.splice(i, 1);
            i = -1;
          }
        }
      }
      let timeNow = new Date().getTime() - 30000;
      for (let syslog of syslogs) {
        syslog.selected = false;
        syslog.new = timeNow - syslog.result.date <= 0;
        syslog.key = `${syslog.gatewayId}-${syslog.deviceId}-${syslog.result.date}-${syslog.result.gatewayTime}`;
      }
      if (
        !this.$tools.isNullOrUndefined(this.$route.params) &&
        !this.$tools.isNullOrUndefined(this.$route.params.logId)
      ) {
        for (let syslog of syslogs) {
          syslog.selected = this.$route.params.logId == syslog.key;
        }
      }
      return syslogs;
    },
    GetGateways() {
      let listOfGateways = [];

      if (!this.$tools.isNullOrUndefined(this.gateways)) {
        for (let gateway of this.gateways) {
          listOfGateways.push({
            text: gateway.name,
            value: gateway.id,
          });
        }
      }

      return listOfGateways;
    },
    GetDevices() {
      let listOfDevices = [];

      if (!this.$tools.isNullOrUndefined(this.devices)) {
        for (let device of this.devices) {
          if (!this.$tools.isNullOrUndefined(this.filterConfig.gateway)) {
            if (this.filterConfig.gateway !== device.gatewayId) continue;
          }
          listOfDevices.push({
            text: device.name,
            value: device.id,
          });
        }
      }

      return listOfDevices;
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off("gateway-syslog");
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Logging" },
    ]);
    let self = this;
    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
    this.$eventBus.on("gateway-syslog", (syslog) => {
      if (syslog.serviceId !== self.$route.params.serviceId) return;

      self.$data.syslogs = self.$data.syslogs || [];
      self.$data.syslogs.unshift(syslog);
      if (self.$data.syslogs.length > 200) {
        self.$data.syslogs.splice(200);
      }
    });
    self.updateData();
  },
};
</script>

<style scoped>
.syslog-row,
.syslog-row .b-table-sticky-column {
  animation: syslog-row 30s 1;
  background: transparent;
  color: var(--dark);
}
@keyframes syslog-row {
  0% {
    background: var(--info);
    color: var(--light);
  }
  90% {
    background: var(--info);
    color: var(--light);
  }
  95% {
    background: var(--light);
    color: var(--dark);
  }
  100% {
    background: rgba(248, 249, 250, 0);
    color: var(--dark);
  }
}
.syslog-row-danger,
.syslog-row-danger .b-table-sticky-column {
  animation: syslog-row-danger 30s 1;
  background: transparent;
  color: var(--dark);
}
@keyframes syslog-row-danger {
  0% {
    background: var(--danger);
    color: var(--light);
  }
  90% {
    background: var(--danger);
    color: var(--light);
  }
  95% {
    background: var(--light);
    color: var(--dark);
  }
  100% {
    background: rgba(248, 249, 250, 0);
    color: var(--dark);
  }
}
.syslog-row-warning,
.syslog-row-warning .b-table-sticky-column {
  animation: syslog-row-warning 30s 1;
  background: transparent;
  color: var(--dark);
}
@keyframes syslog-row-warning {
  0% {
    background: var(--warning);
    color: var(--light);
  }
  90% {
    background: var(--warning);
    color: var(--light);
  }
  95% {
    background: var(--light);
    color: var(--dark);
  }
  100% {
    background: rgba(248, 249, 250, 0);
    color: var(--dark);
  }
}
.syslog-select {
  background: var(--success);
  color: var(--light);
}
</style>
